export type ChampID =
  | "ahri"
  | "akali"
  | "alistar"
  | "amumu"
  | "annie"
  | "blitzcrank"
  | "braum"
  | "fizz"
  | "garen"
  | "janna"
  | "jarvan"
  | "jax"
  | "jinx"
  | "lux"
  | "malphite"
  | "miss_fortune"
  | "nami"
  | "seraphine"
  | "sona"
  | "twisted_fate"
  | "varus"
  | "vi"
  | "zed"
  | "ziggs";

export const champIDToURL = (champ: ChampID): string => {
  if (champ === "jarvan") champ = "jarvan-iv" as ChampID;
  return champ.replace("_", "-");
};
