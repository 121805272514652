import gsap, { TweenTarget } from "gsap";
import React, { useCallback, useContext, useEffect, useMemo, useRef } from "react";
import styled from "styled-components";
import { useClipboard } from "use-clipboard-copy";
import { Link } from "../components/Layout/style";
import { device, MainTheme } from "../components/Layout/theme";
import { ChampID } from "../data/champs";
import Locale from "../data/contexts/riotLocale";
import { getIntl } from "../helpers/rioti18n";
import Icon from "./General/Icon/Icon";

type ButtonProps = {
  copied?: boolean;
};

const ShareStyled = styled.div`
  position: relative;
  padding: 0 20px 0 0;

  &::before {
    content: "";
    width: 1px;
    height: 25px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    background: #a1a5af;

    @media ${device.minWidth768} {
      display: none;
    }
  }

  @media ${device.minWidth768} {
    min-width: 258px;
  }
`;
const ShareButtonWrapper = styled.div`
  display: flex;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;

  @media ${device.minWidth768} {
    width: 80%;
    margin-right: 6rem;
  }
`;

const ShareButton = styled(Link)`
  position: relative;
  z-index: 2;

  @media ${device.minWidth768} {
    margin-right: 6rem;

    &:hover {
      span {
        transform: rotate(0);
      }
    }
  }

  span {
    transform: scale(0.8);

    @media ${device.minWidth768} {
      transform: scale(1);
    }
  }

  &:focus {
    outline: none;
  }
`;

const ShareIconButton = styled(Link)`
  margin-right: 3.2rem;

  span {
    color: ${MainTheme.colorMidnightBlue};
    margin-left: 0;
  }

  &:nth-child(2) {
    left: 4px;
    position: relative;
  }

  &:focus {
    outline: none;
  }
`;
const CopyLink = styled(ShareIconButton)<ButtonProps>`
  margin-right: 0;
  position: relative;

  span:nth-of-type(1) {
    transition: opacity 180ms ease;
    opacity: ${(props): string => (props.copied ? "0" : "1")};
  }
  span:nth-of-type(2) {
    position: absolute;
    transition: opacity 180ms ease;
    opacity: ${(props): string => (props.copied ? "1" : "0")};
  }
`;

type ShareProps = {
  champ: ChampID;
};

export const Share: React.FC<ShareProps> = ({ champ }) => {
  const { t } = getIntl();
  const locale = useContext(Locale);
  const buttonWrapperRef = useRef<HTMLDivElement | null>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const onClick = useCallback(() => {
    const timeline = gsap.timeline();
    timeline.to(buttonRef.current, { autoAlpha: 0, y: 20, duration: 0.25 });
    timeline.to(buttonWrapperRef.current?.querySelectorAll("button") as TweenTarget, {
      autoAlpha: 1,
      y: 0,
      duration: 0.25,
      display: "flex",
      stagger: 0.2,
    });
  }, []);
  useEffect(() => {
    gsap.set(buttonWrapperRef.current?.querySelectorAll("button") as TweenTarget, {
      autoAlpha: 0,
      y: -10,
    });
  }, [buttonWrapperRef]);
  const { copied, copy } = useClipboard({ copiedTimeout: 600 });

  const url = useMemo(
    () => `https://findyourchampion.wildrift.leagueoflegends.com/${locale}${t(`champ.${champ}.url`)}`,
    [champ, locale],
  );
  const onCopyLinkClick = useCallback(() => {
    copy(url);
  }, []);
  const onTwitterClick = useCallback(() => {
    let shareURL = "http://twitter.com/share?";
    const params = {
      url,
      text: t(`champ.${champ}.headline`),
      hashtags: [].join(","),
    } as const;
    Object.keys(params).forEach((param) => {
      shareURL += `&${param}=${encodeURIComponent(params[param as keyof typeof params])}`;
    });
    window.open(shareURL, "", "left=0,top=0,width=550,height=450,personalbar=0,toolbar=0,scrollbars=0,resizable=0");
  }, [url]);
  const onFacebookClick = useCallback(() => {
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${url}`,
      "",
      "left=0,top=0,width=550,height=450,personalbar=0,toolbar=0,scrollbars=0,resizable=0",
    );
  }, [url]);

  return (
    <ShareStyled>
      <ShareButton onClick={onClick} ref={buttonRef} data-testid="share:share-button">
        {t("results.cta.share")}
        <Icon name={"Share"} size={23} />
      </ShareButton>
      <ShareButtonWrapper ref={buttonWrapperRef}>
        <ShareIconButton
          onClick={onFacebookClick}
          data-interaction-id="prefooter-facebook_share"
          data-testid="share:facebook-button"
        >
          <Icon name={"Facebook"} size={23} />
        </ShareIconButton>
        <ShareIconButton
          onClick={onTwitterClick}
          data-interaction-id="prefooter-twitter_share"
          data-testid="share:twitter-button"
        >
          <Icon name={"Twitter"} size={23} />
        </ShareIconButton>

        <CopyLink
          onClick={onCopyLinkClick}
          copied={copied}
          data-interaction-id="prefooter-fyc_share"
          data-testid="share:copylink-button"
        >
          <Icon name={"CopyLink"} size={23} />
          <Icon name={"Check"} size={23} />
        </CopyLink>
      </ShareButtonWrapper>
    </ShareStyled>
  );
};
