import { PageProps } from "gatsby"; // This part is important - it helps with instructing gatsby to inject the context passed in
import { Link as LinkComponent } from "gatsby-plugin-intl";
import React, { useEffect, useMemo, useRef } from "react";
import styled from "styled-components";
import bgButton from "../assets/images/bg_button_mobile.jpg";
import Icon from "../components/General/Icon/Icon";
import Layout from "../components/Layout";
import { ContentWrapper, Link } from "../components/Layout/style";
import { device, fontCopy, fontHeading, MainTheme } from "../components/Layout/theme";
import SEO from "../components/seo";
import { Share } from "../components/Share";
import { ChampID, champIDToURL } from "../data/champs";
import { videos } from "../data/videos";
import { BCPLocale, getIntl } from "../helpers/rioti18n";
import { useIsMobile } from "../hooks/useIsMobile";

const ChampPageWrapper = styled.div`
  position: relative;
  width: 100%;
  text-align: center;

  @media ${device.minWidth768} {
    text-align: left;
    height: calc(100vh - 80px);
  }
`;

const ChampNameWrapper = styled.div<{ hasDetails: boolean }>`
  position: absolute;
  bottom: ${(props): string => (props.hasDetails ? "-25px" : "40px")};
  left: 0;
  width: 100%;
  color: #fff;
  padding: 0 20px;
  z-index: 1;

  @media ${device.minWidth768} {
    padding: 0 100px;
    bottom: inherit;
    top: calc(50% - 10%);
    transform: translate(0, -50%);
  }
`;

const ChampEyebrow = styled.h4`
  ${fontCopy};
  color: #fff;
  font-size: 13px;
  letter-spacing: 0.8px;

  @media ${device.minWidth768} {
    font-size: 16px;
    letter-spacing: 0;
  }
`;

const ChampName = styled.h2`
  ${fontHeading};
  font-size: 45px;
  line-height: 50px;
  letter-spacing: 1px;
  margin-top: 5px;

  @media ${device.minWidth768} {
    font-size: 80px;
    line-height: 70px;
    margin: 15px 0 30px;
  }
`;

const MainButton = styled.a`
  ${fontHeading};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  height: 50px;
  padding: 0 30px;
  position: relative;
  margin-top: 22px;
  letter-spacing: 1px;

  @media ${device.minWidth768} {
    margin-top: 5px;
    height: 55px;
    font-size: 19px;
  }

  &::after {
    content: "";
    transition: ${MainTheme.colorTransition};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${MainTheme.colorElectricBlue};
    transform: skewX(-20deg);
    z-index: -1;
    letter-spacing: 1px;
  }

  &:hover {
    &::after {
      background-color: ${MainTheme.colorMidnightBlue};
      transition: ${MainTheme.colorTransition};
    }
  }

  &:focus {
    outline: none;
  }
`;

const ChampVideo = styled.video`
  height: 100%;
  clip-path: polygon(0 0, 100% 0%, 100% 125vw, 0% 125vw);
  width: 100%;
  height: 100%;
  object-fit: cover;
  left: 50%;
  position: relative;
  transform: translate(-50%, 0);

  // IE 11
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    max-width: none;
    position: absolute;
    width: 220vh;
    min-height: 77vh;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @media ${device.minWidth768} {
    clip-path: polygon(0 0, 100% 0%, 100% 100vh, 0% 100vh);
  }
`;

const ChampVideoWrapper = styled.div`
  width: 100%;
  height: 125vw;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 30px;
    height: 30px;
    background: white;
    z-index: 1;
    transform: translate(-50%, 0) skewX(45deg);

    @media ${device.minWidth768} {
      width: 55px;
      height: 50px;
      bottom: 20%;
      left: -5px;
      transform: translate(-50%, 0) skewX(45deg);
    }
  }

  @media ${device.minWidth768} {
    height: 100%;
  }

  img {
    max-width: 100%;
    height: 100%;
    object-fit: cover;
    left: 50%;
    position: relative;
    transform: translate(-50%, 0);
  }
`;

const ChampsFooter = styled.footer`
  text-align: center;
  width: 100%;
  background: #fff;
  display: flex;
  align-items: center;

  @media ${device.minWidth768} {
    height: 20%;
    position: absolute;
    bottom: 0;
    left: 0;
  }
`;

const FooterWrapper = styled(ContentWrapper)`
  height: auto;
  padding: 0;

  @media ${device.minWidth768} {
    display: flex;
    flex-direction: row;
    padding: 0 3.2rem;
  }
`;

const LinksWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px auto 40px;

  @media ${device.minWidth768} {
    border-right: 1px solid rgba(0, 0, 0, 0.4);
    justify-content: flex-end;
    padding-right: 8rem;
    margin: 0;
  }

  ${(props): string =>
    props.theme.isRTL
      ? `
        @media ${device.minWidth768}  {
          border-left: 1px solid rgba(0, 0, 0, 0.4);
          border-right:initial;

          width: 50%;
          padding-left: 8rem;
          padding-right:initial;
          margin-bottom: 0;
        }
      `
      : ``}
`;

const FooterButtonWrapper = styled.div`
  height: 110px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url(${bgButton});
  background-size: cover;

  @media ${device.minWidth768} {
    background: none;
    height: auto;
    justify-content: flex-start;
  }
`;

const RestartButton = styled(Link)`
  margin: 0 0 0 20px;
  white-space: nowrap;

  span {
    transition: transform 0ms ease;
    transform: scale(0.8);

    @media ${device.minWidth768} {
      transform: scale(1) rotate(0);
    }
  }

  &:hover {
    span {
      transform: scale(1) rotate(360deg);
      transition: transform 350ms ease-in-out;
    }
  }
`;

const FooterButton = styled(MainButton)`
  z-index: 1;
  margin-top: 0;
  color: ${MainTheme.colorMidnightBlue};

  @media ${device.minWidth768} {
    color: ${MainTheme.colorWhite};
    margin-left: 10rem;
    min-width: 270px;
  }

  &::after {
    background-color: ${MainTheme.colorWhite};
    z-index: -1;

    @media ${device.minWidth768} {
      background-color: ${MainTheme.colorMidnightBlue};
    }
  }

  &:hover {
    &::after {
      background-color: ${MainTheme.colorElectricBlue};
    }
  }

  ${(props): string =>
    props.theme.isRTL
      ? `
        @media ${device.minWidth768}  {
          margin-left: initial;
          margin-right:10rem;
        }
      `
      : ``}
`;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ChampPageProps {} // Empty interface, generally speaking no regular props are passed

export type ChampPageData = {
  champ: ChampID;
  path: string;
};

interface ChampPageContextData {
  pageData: ChampPageData; // This is what gets passed into the page context from the createPage function from the gatsby-node.ts
  language: BCPLocale;
}
// Because this is a "Page" in gatsby, not a standard component, the props must extend the PageProps
type PromoPageContentstackProps = PageProps<ChampPageProps, ChampPageContextData>;

const ChampPage: React.FC<PromoPageContentstackProps> = ({
  pageContext: {
    pageData: { champ },
    language: locale,
  },
}) => {
  const { t } = getIntl();
  const champSlug = useMemo(() => champIDToURL(champ), [champ]);
  const isMobile = useIsMobile();
  const champVideoRef = useRef<HTMLVideoElement | null>(null);
  const showPlayNow = useMemo(
    () =>
      [
        BCPLocale["en-sg"],
        BCPLocale["id-id"],
        BCPLocale["th-th"],
        BCPLocale["ms-my"],
        BCPLocale["ko-kr"],
        BCPLocale["ja-jp"],
        BCPLocale["en-us"],
        BCPLocale["es-mx"],
        BCPLocale["pt-br"],
      ].includes(locale),
    [locale],
  );
  useEffect(() => {
    if (!champVideoRef.current) return;
    champVideoRef.current.setAttribute("muted", "");
  }, [champVideoRef.current]);

  return (
    <Layout>
      <SEO
        title={`${t(`champ.${champ}`)} | ${t("homepage.eyebrow")}`}
        description={t(`champ.${champ}.description`)}
        meta={[
          {
            name: "og:image",
            content: `https://findyourchampion.wildrift.leagueoflegends.com/images/og/${champ}.jpg`,
          } as HTMLMetaElement,
        ]}
      />
      <ChampPageWrapper>
        <ChampVideoWrapper>
          <ChampVideo
            autoPlay={true}
            ref={champVideoRef}
            muted={true}
            loop={true}
            playsInline={true}
            src={`${videos[champ][isMobile ? "mobile" : "desktop"]}`}
            data-testid="hero:video"
          />
          <ChampNameWrapper hasDetails={true}>
            <ChampEyebrow data-testid="hero:subtitle">
              {
                // eslint-disable-next-line @typescript-eslint/camelcase
                t("results.title", { champ_name: "" })
              }
            </ChampEyebrow>
            <ChampName data-testid="hero:title">{t(`champ.${champ}`)}</ChampName>
            <MainButton
              href={`https://wildrift.leagueoflegends.com/${locale}/champions/${champSlug}`}
              data-interaction-id="hero-champ_details"
              data-testid="hero:detail-button"
            >
              {t("results.cta.to-champ-page")}
            </MainButton>
          </ChampNameWrapper>
        </ChampVideoWrapper>

        <ChampsFooter>
          <FooterWrapper>
            <LinksWrapper>
              <Share champ={champ} />
              <RestartButton
                as={LinkComponent}
                to={"/"}
                data-interaction-id="prefooter-start_again"
                data-testid="hero:restart-button"
              >
                {t("results.cta.start-again")}
                <Icon name={"Restart"} size={23} />
              </RestartButton>
            </LinksWrapper>
            <FooterButtonWrapper>
              <FooterButton
                href="https://riotgames.sng.link/Dus0s/bvh9"
                data-interaction-id="prefooter-pre_register"
                data-testid="hero:register-button"
              >
                {t(`results.cta.${showPlayNow ? "app-store" : "app-store-preregister"}`)}
              </FooterButton>
            </FooterButtonWrapper>
          </FooterWrapper>
        </ChampsFooter>
      </ChampPageWrapper>
    </Layout>
  );
};
export default ChampPage;
