import { useEffect, useState } from "react";

export const useIsMobile = (): boolean => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (typeof window === "undefined") return;

    const setMediaMatchHandler = (event: MediaQueryListEvent | MediaQueryList): void => {
      setIsMobile(event.matches);
    };

    const mediaQueryList = window.matchMedia("(max-width: 768px)");

    setMediaMatchHandler(mediaQueryList);

    mediaQueryList.addListener(setMediaMatchHandler);

    return (): void => mediaQueryList.removeListener(setMediaMatchHandler);
  }, []);

  return isMobile;
};
