import { ChampID } from "./champs";

type Videos = {
  desktop: string;
  mobile: string;
};

/* eslint-disable @typescript-eslint/camelcase */
export const videos: Record<ChampID, Videos> = {
  akali: {
    desktop:
      "https://assets.contentstack.io/v3/assets/blt370612131b6e0756/blta99efe052ca591c5/5f9c3d5b2425cd7a8af69fd1/Akali_TurnTable_2048.mp4",
    mobile:
      "https://assets.contentstack.io/v3/assets/blt370612131b6e0756/blt479243c49514e166/5f90a0e83bf9954172cf9f73/Akali_TurnTable_1_m_2.mp4",
  },
  seraphine: {
    desktop:
      "https://assets.contentstack.io/v3/assets/blt370612131b6e0756/blt8cb2c7325d4761c9/5f8dffb53db5122648cbdf7c/Seraphine_TurnTable_1_compressed.mp4",
    mobile:
      "https://assets.contentstack.io/v3/assets/blt370612131b6e0756/blt26a3ded37a02ea17/5f9c3d861f9166620ed87601/Seraphine_TurnTable_2048.mp4",
  },
  ahri: {
    mobile:
      "https://assets.contentstack.io/v3/assets/blt370612131b6e0756/blt20544c48f5eb6687/5f8dd3c3b74e541ab558ed67/ahri_turntable_1_20.mp4",
    desktop:
      "https://assets.contentstack.io/v3/assets/blt370612131b6e0756/blt2e13ea96143893bc/5f9c3c2d6c73a0751fc7ac86/Ahri_TurnTable_2048.mp4",
  },
  alistar: {
    mobile:
      "https://assets.contentstack.io/v3/assets/blt370612131b6e0756/bltfb0e54954b3b4a5a/5f8dd6612c58c80ec02ec4a0/alistar_turntable_1_20.mp4",
    desktop:
      "https://assets.contentstack.io/v3/assets/blt370612131b6e0756/bltcdf57274dd27b692/5f7f70501a0a7b0f226b86ec/alistar_turntable_1.mp4",
  },
  amumu: {
    mobile:
      "https://assets.contentstack.io/v3/assets/blt370612131b6e0756/bltd82120230ea12efd/5f8dd666f8117f10ece4d56f/amumu_turntable_1_20.mp4",
    desktop:
      "https://assets.contentstack.io/v3/assets/blt370612131b6e0756/bltdbf112be500c3288/5f7f7069efdbc80efd0707bb/amumu_turntable_1.mp4",
  },
  annie: {
    mobile:
      "https://assets.contentstack.io/v3/assets/blt370612131b6e0756/blt241707c1d3077a90/5f8dd6704eb7997a515a536d/annie_turntable_1_20.mp4",
    desktop:
      "https://assets.contentstack.io/v3/assets/blt370612131b6e0756/blta8228ca0c1b66c75/5f7f70853088980ee66121c9/annie_turntable_1.mp4",
  },
  blitzcrank: {
    mobile:
      "https://assets.contentstack.io/v3/assets/blt370612131b6e0756/blt590cc7e3814acd56/5f8dd76318967612e58b0dcd/blitzcrank_turntable_1_20.mp4",
    desktop:
      "https://assets.contentstack.io/v3/assets/blt370612131b6e0756/blt04e604c837a7e75e/5f7f70d57f76200f1733c1a6/blitzcrank_turntable_1.mp4",
  },
  braum: {
    mobile:
      "https://assets.contentstack.io/v3/assets/blt370612131b6e0756/blte42d52273795ba4b/5f8dd76ade194e1bde3fbb3d/braum_turntable_1_20.mp4",
    desktop:
      "https://assets.contentstack.io/v3/assets/blt370612131b6e0756/blt1e910021d22fa3f7/5f7f70f1efdbc80efd0707e7/braum_turntable_1.mp4",
  },
  fizz: {
    mobile:
      "https://assets.contentstack.io/v3/assets/blt370612131b6e0756/blt20b13817c1f78ff2/5f8dd783f8117f10ece4d587/fizz_turntable_1_20.mp4",
    desktop:
      "https://assets.contentstack.io/v3/assets/blt370612131b6e0756/blt336b8af95869a48c/5f7f7179e91fb80ef8022bd3/fizz_turntable_1.mp4",
  },
  garen: {
    mobile:
      "https://assets.contentstack.io/v3/assets/blt370612131b6e0756/blt8a8ff922ad549bf5/5f8dd7895105071081af7351/garen_turntable_1_20.mp4",
    desktop:
      "https://assets.contentstack.io/v3/assets/blt370612131b6e0756/blt46ccf6b4f108340e/5f7f719586abe10efc8eaad6/garen_turntable_1.mp4",
  },
  janna: {
    mobile:
      "https://assets.contentstack.io/v3/assets/blt370612131b6e0756/blt8ed2d5bbc38c30fb/5f8dd797f719161444544a37/janna_turntable_1_20.mp4",
    desktop:
      "https://assets.contentstack.io/v3/assets/blt370612131b6e0756/bltf07c656ff12a9d50/5f7f71e8bea0360f96bae7fc/janna_turntable_1.mp4",
  },
  jarvan: {
    mobile:
      "https://assets.contentstack.io/v3/assets/blt370612131b6e0756/blt086ac01e653a89ec/5f8dd79c636d58106b859991/jarvaniv_turntable_1_20.mp4",
    desktop:
      "https://assets.contentstack.io/v3/assets/blt370612131b6e0756/bltba1b4662069c1ecc/5f7f7204d48a690f08f8b741/jarvaniv_turntable_1.mp4",
  },
  jax: {
    mobile:
      "https://assets.contentstack.io/v3/assets/blt370612131b6e0756/bltc105270d35a45d4d/5f8dd7a283a36a1be55f51e5/jax_turntable_1_20.mp4",
    desktop:
      "https://assets.contentstack.io/v3/assets/blt370612131b6e0756/bltde77e81b12031a27/5f7f7221b65c110f6aa8e3c2/jax_turntable_1.mp4",
  },
  jinx: {
    mobile:
      "https://assets.contentstack.io/v3/assets/blt370612131b6e0756/bltfab6784042070e46/5f8dd7acf8117f10ece4d58d/jinx_turntable_1_20.mp4",
    desktop:
      "https://assets.contentstack.io/v3/assets/blt370612131b6e0756/blt4714b5469ba136fa/5f7f7254c1be730f80a84ac0/jinx_turntable_1.mp4",
  },
  lux: {
    mobile:
      "https://assets.contentstack.io/v3/assets/blt370612131b6e0756/bltf3e38c262e729e79/5f8dd7b6b5866d0d5c41466f/lux_turntable_1_20.mp4",
    desktop:
      "https://assets.contentstack.io/v3/assets/blt370612131b6e0756/blt16111cf84b3c0351/5f7f7a00a1441e0f5fc46ca5/lux_turntable_1.mp4",
  },
  malphite: {
    mobile:
      "https://assets.contentstack.io/v3/assets/blt370612131b6e0756/blt3609a8879cc9fcf5/5f8dd7bd4eb7997a515a537b/malphite_turntable_1_20.mp4",
    desktop:
      "https://assets.contentstack.io/v3/assets/blt370612131b6e0756/blt498b770c7defd731/5f7f7a1a2b9e620efd874494/malphite_turntable_1.mp4",
  },
  miss_fortune: {
    mobile:
      "https://assets.contentstack.io/v3/assets/blt370612131b6e0756/bltef1abcf3b8bd5fa6/5f8dd7c4722f2a15c7baccf8/missfortune_turntable_1_20.mp4",
    desktop:
      "https://assets.contentstack.io/v3/assets/blt370612131b6e0756/blt9b080ddb04b633c8/5f7f7a59ee00c80ec595b0ca/missfortune_turntable_1.mp4",
  },
  nami: {
    mobile:
      "https://assets.contentstack.io/v3/assets/blt370612131b6e0756/bltfe85ec2a3b1b99e2/5f8dd7caf13ed90f8d8729b6/nami_turntable_1_20.mp4",
    desktop:
      "https://assets.contentstack.io/v3/assets/blt370612131b6e0756/bltf702b0c7f5e45c1b/5f7f7a73472a560f4335f4fc/nami_turntable_1.mp4",
  },
  sona: {
    mobile:
      "https://assets.contentstack.io/v3/assets/blt370612131b6e0756/bltee7e4f59cdf35670/5f8dd7f36c54ba0f72c2ac1c/sona_turntable_1_20.mp4",
    desktop:
      "https://assets.contentstack.io/v3/assets/blt370612131b6e0756/blt1766efe87f22b931/5f7f7b172b9e620efd8744c6/sona_turntable_1.mp4",
  },
  twisted_fate: {
    mobile:
      "https://assets.contentstack.io/v3/assets/blt370612131b6e0756/blt376b1e4c5ca9de74/5f8dd80df6a0a50f825ba6ef/twistedfate_turntable_1_20.mp4",
    desktop:
      "https://assets.contentstack.io/v3/assets/blt370612131b6e0756/blt108abcd7471767f9/5f7f7b680fa7480f8b8d7fa9/twistedfate_turntable_1.mp4",
  },
  varus: {
    mobile:
      "https://assets.contentstack.io/v3/assets/blt370612131b6e0756/blt10d65375eced0bab/5f8dd8161c5db60f7d0b8af7/varus_turntable_1_20.mp4",
    desktop:
      "https://assets.contentstack.io/v3/assets/blt370612131b6e0756/blt30af41503beb701b/5f7f7b880108fe0f08577fe7/varus_turntable_1.mp4",
  },
  vi: {
    mobile:
      "https://assets.contentstack.io/v3/assets/blt370612131b6e0756/bltcd7504a4c1283255/5f8dd8215f28f610c0ba8c0d/vi_turntable_1_20.mp4",
    desktop:
      "https://assets.contentstack.io/v3/assets/blt370612131b6e0756/blt9476ddeadb0ad2bb/5f7f7bc10fa7480f8b8d7fb3/vi_turntable_1.mp4",
  },
  zed: {
    mobile:
      "https://assets.contentstack.io/v3/assets/blt370612131b6e0756/blt9e5c7e6f83df635d/5f8dd833f719161444544a3f/zed_turntable_1_20.mp4",
    desktop:
      "https://assets.contentstack.io/v3/assets/blt370612131b6e0756/blt7d650ca2af2c9610/5f7f7c33b5b2ab0fa1dd2887/zed_turntable_1.mp4",
  },
  ziggs: {
    mobile:
      "https://assets.contentstack.io/v3/assets/blt370612131b6e0756/blt48d8a51da640bf07/5f8dd83907d89910f7bfffc6/ziggs_turntable_1_20.mp4",
    desktop:
      "https://assets.contentstack.io/v3/assets/blt370612131b6e0756/bltf133658f69161bcd/5f7f7c56fbf5650f0cf148c9/ziggs_turntable_1.mp4",
  },
} as const;
